import { Box, DialogActions, FormControl, Grid, List, ListItem, ListItemText, useTheme } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import React, { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import CustomDatePicker from "../../common/CustomDatePicker";
import dayjs from "dayjs";
import Button from "../../common/Button";
import {
  SingleAlertInfo,
  fetchMac,
  setSingleAlertObj,
  useCommonReducer,
} from "../../../redux/slice/commonSlice";
import CustomTable from "../../common/CustomTable";
import {
  PracticeInfo,
  getPracticesByUserRole,
  usePracticeSlice,
} from "../../../redux/slice/practiceSlice";
import {
  AddCircleOutlineOutlined,
  Cancel,
  DeleteOutlineOutlined,
  Details,
  Save,
} from "@mui/icons-material";
import {
  calculateValue,
  priceFormatter,
  sortNames,
} from "../../../utils/Utility";
import {
  createProductAgreement,
  PracticeProductInfo,
} from "../../../redux/slice/productAgreement/createAgreementSlice";
import moment from "moment";
import Header from "../../common/Header";
import Card from "../../common/Card";
import { useNavigate } from "react-router-dom";
import PracticeDetailsPopup from "./PracticeDetailsPopup";
import {
  ProductInfo,
  ProductPrice,
  ProductPriceByProductRequest,
  fetchProductsByUserRoleData,
  getProductPriceByProductId,
  updateElementInProductListSlice,
  useProductListReducer,
} from "../../../redux/slice/product/productListSlice";
import OptionsPopup, {
  OptionPopupObjectProps,
} from "../../common/OptionsPopup";
import {
  fetchAllUserRoles,
} from "../../../redux/slice/userSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import CustomTextField from "../../common/CustomTextField";
import CustomRadioButton from "../../common/CustomRadioButton";
import { MSG_ACTIVE_PRODUCT_AGREEMENT_FOUND, RESPONSE_CODES } from "../../../constants/applicationConstants";
import CustomCurrencyField from "../../common/CustomCurrencyField";
import { fetchPracticeProductsData, usePracticeProductsSlice } from "../../../redux/slice/practice/practiceProductSlice";
import { fetchPracticeByIdData, usePracticeEditSlice } from "../../../redux/slice/practice/practiceEditSlice";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Typography,
  Paper,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { tokens } from "../../../theme";
import CustomIconButton from "../../common/CustomIconButton";

// Sample data
const mainRow = {
  name: 'Agreement Name',
  description: 'Main agreement details.',
  subTable: [
    { key: 'Sub Item 1', value: 'Detail 1' },
    { key: 'Sub Item 2', value: 'Detail 2' },
    { key: 'Sub Item 3', value: 'Detail 3' },
  ],
};

interface CreateNewAgreementNewProps {}

interface ProductRow {
  productId : number;
  productPriceId: number;
  name: string;
  sku?: string;
  price?: string;
  discount?: string;
  extendedPrice?: string;
  extra?: boolean; 
}


// Define the grouped row type
interface GroupedRow {
  mainRow: ProductRow;
  subRows: ProductRow[];
}

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "left" | "center" | "right";
  format?: (value: number) => string;
}

interface Product {
  productId?: string;
  name?: string;
  discount: JSX.Element;
  price: string | null;
  extendedPrice: any;
  productPriceId: string | null;
  close: JSX.Element;
}

type NewAgreementState = {
  practiceId: string | null;
  product: string | null;
  practice: PracticeInfo[];
  practiceName: string | null;
  effectiveDate: string | null;
  // inactiveDate: string | null;
  macId: string | null;
  practiceProducts: PracticeProductInfo[];
  extendedValues: string[] | null;
  discount: string[] | null;
  radio: string[] | null;
  customPrice: string[] | null;
};

const initialAgreement: NewAgreementState = {
  practiceId: null,
  product: null,
  practiceName: null,
  effectiveDate: null,
  practice: [],
  // inactiveDate: null,
  macId: null,
  practiceProducts: [],
  extendedValues: [],
  discount: [],
  radio: [],
  customPrice: []
};

const productColumns: Column[] = [
  { id: "name", label: "Product", minWidth: 100 },
  { id: "price", label: "Allowable Price", minWidth: 100},
  { id: "discount", label: "Discount", minWidth: 100, align: "center" },
  { id: "extendedPrice", label: "Extended Price", minWidth: 100},
  { id: "action", label: "Action", minWidth: 50 },
];


const subColumns: Column[] = [
  { id: "sku", label: "SKU", minWidth: 100 },
  { id: "priceAllowable", label: "Allowable Price", minWidth: 100},
  { id: "discount", label: "Discount", minWidth: 100, align: "center" },
  { id: "extendedPrice", label: "Extended Price", minWidth: 100},
];


type Option = {
  value: string | number;
  label: string;
};

const CreateNewAgreementNew: React.FC<CreateNewAgreementNewProps> = () => {
  const { control, handleSubmit, reset, watch, getValues, setValue } =
    useForm<NewAgreementState>({ defaultValues: initialAgreement });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { macInfoList } = useCommonReducer();
  const { productsByUserRole, ProductPriceListForSingleProduct } = useProductListReducer();
  const { practiceByUserRole } = usePracticeSlice();
  const [productRows, setProductRows] = React.useState<any[]>([]);
  const [showPracticeDetails, setShowPracticeDetails] = React.useState(false);
  const [isPracticeDisable, setIsPracticeDisable] = React.useState(false);
  const [isMacDisable, setIsMacDisable] = React.useState(false);
  const [showOptionPopup, setShowOptionsPopup] =
    React.useState<OptionPopupObjectProps>({ message: "", show: false });
  const [isEfctDateDisable, setIsEfctDateDisable] = React.useState(false);
  const [ maxInactiveDate, setMaxInactiveDate ] = React.useState(dayjs().add(20, 'year'));
  const [ minInactiveDate, setMinInactiveDate ] = React.useState(dayjs());
  const [ enabledDiscountList, setEnabledDiscountList ] = React.useState([]);
  const [ enabledCustomPrice, setEnabledCustomPrice ] = React.useState([]);
  const [ practiceData, setPracticeData] = React.useState<PracticeInfo | null>(null);
  const [ practiceProductData, setPracticeProductData] = React.useState<PracticeProductInfo[]>([]);

  const [isCollapsed, setIsCollapsed] = React.useState<number | null>(null);
  const [collapsedState, setCollapsedState] = React.useState<{ [key: number]: boolean }>({ 0: false });

  const [isCustomDiscountRadio, setCustomDiscountRadio] = React.useState(false);
  const [isCustomPriceRadio, setCustomPriceRadio] = React.useState(false);


  const handleCollapseToggle = (mainIndex: number) => {
    setCollapsedState((prev) => ({
      ...prev,
      [mainIndex]: !prev[mainIndex],
    }));
  };

  // Mounting
  useEffect(() => {
    const date = new Date();
    setValue("effectiveDate", moment(date).format("MM/DD/YYYY"));
    dispatch(fetchMac());
    dispatch(fetchAllUserRoles());
    dispatch(fetchProductsByUserRoleData(true));
    dispatch(getPracticesByUserRole(true));
  }, []);

  const discountComponent = (
    prod: ProductInfo | undefined,
    allowableUnitPrice: any,
    customPrice: any,
    disableUnit?: string,
    productPrice?: ProductPrice
  ): JSX.Element => {
    
    return (
      <>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item>
              <CustomRadioButton
                control={control}
                controllerName={`radio.discount${productPrice?.productPriceId}`}
                options={radioButtonOptions(prod, allowableUnitPrice, customPrice, disableUnit, productPrice)}
                onChange={(e) => handleDiscount(prod, allowableUnitPrice, customPrice, e.target.value, productPrice)}
              />
          </Grid>
        </Grid>
      </>
    );
  }

  const radioButtonOptions = (prod: ProductInfo | undefined, allowableUnitPrice: any, customPrice: number | null, disableUnit?:string, productPrice?: ProductPrice) => {
    const options: { value: string | number; label: string | JSX.Element }[] = [
      {
        label: <CustomTextField
          control={control}
          controllerName={`discount.discount${productPrice?.productPriceId}`}
          label="Discount %"
          onChange={(e) =>
            setDiscountOnFieldChange(prod, allowableUnitPrice, e.target.value, productPrice)}
          type="number"
          isDisabled={!!(!disableUnit || disableUnit === "customDiscount")}
          rules={{
            min: { value: 1, message: "Minimum value allowed is 1" },
            max: { value: 100, message: "Maximum value allowed is 100" }
          }}
          size='small'
          style={{width: '150px'}}
          />,
        value: `customDiscount`
      }, 
      {
        label: <CustomCurrencyField
        control={control}
        controllerName={`customPrice.customPrice${productPrice?.productPriceId}`}
        label="Custom Price"
        onChange={(e) =>
          e?.target?.value && setCustomPriceChange(prod, allowableUnitPrice, e?.target?.value, productPrice)}
        isDisabled={!!(!disableUnit || disableUnit === "customPrice")}
        size='small'
        style={{width: '150px'}}
        />,
        value: 'customPrice'
      }
    ]
    return options;
  }

  const closeComponent = (productPriceId: any): JSX.Element => {
    return (
      <>
        <FormControl>
          <CustomIconButton 
            color="error"
            size="medium"
            variant="outlined"
            onClick={() => handleClose(productPriceId)}
          >
            <DeleteOutlineOutlined fontSize="small" />
          </CustomIconButton>
        </FormControl>
      </>
    );
  };

  const handleClose = (productPriceId: any) => {
    let products: PracticeProductInfo[] | null = getValues("practiceProducts");
    const practiceId = getValues("practiceId");
    const macId = getValues("macId");
    const effectiveDate = dayjs(getValues("effectiveDate"));
    products = products?.filter(
      (product) => productPriceId !== product.productPriceId
    );
    deleteDiscountComponentRow(productPriceId);
    setProductRows(prevRows => {
      const newRows = prevRows.filter((row) => row.productPriceId !== productPriceId);
      return newRows;
    });
    setValue("practiceProducts", products);
    if (products?.length > 0) {
      setIsPracticeDisable(!!practiceId);
      setIsMacDisable(!!macId);
      setIsEfctDateDisable(effectiveDate.isValid() ?? false);
    } else {
      setIsPracticeDisable(false);
      setIsEfctDateDisable(false);
      setIsMacDisable(false);
    }
  };

  const setDiscount = (    
    allowableUnitPrice: any,
    discount: string,
    products: PracticeProductInfo[],
    productPriceId?: string) => {
      products?.map((product) => {
        if (product.productPriceId === productPriceId) {
          product.discountType = discount;
          product.extendedPrice = allowableUnitPrice
            ? calculateValue(discount, allowableUnitPrice)
            : null;
        }
      }); 
      setProductRows((prevRows) => {
        return prevRows.map((row) => {
          if (row.productPriceId === productPriceId) {
            return {
              ...row,
              extendedPrice: allowableUnitPrice
                ? priceFormatter(calculateValue(discount, allowableUnitPrice))
                : null,
            };
          }
          return row;
        });
      });
      return products;
  };

  const setCustomPrice = (    
    allowableUnitPrice: any,
    value: number,
    products: PracticeProductInfo[],
    productPriceId? : string) => {
      products?.map((product) => {
        if (product.productPriceId === productPriceId) {
          product.discountType = "CUSTOM";
          product.extendedPrice = allowableUnitPrice
            ? value
            : null;
        }
      }); 
      setProductRows((prevRows) => {
        return prevRows.map((row) => {
          if (row.productPriceId === productPriceId) {
            return {
              ...row,
              extendedPrice: allowableUnitPrice
                ? priceFormatter(value)
                : null,
            };
          }
          return row;
        });
      });
      return products;
  };

  const setDiscountOnFieldChange = (
    prod: ProductInfo | undefined,
    allowableUnitPrice: any,
    discount: string,
    productPrice?: ProductPrice
  ) => {

    console.log(allowableUnitPrice+"----------------------------")
    setEnabledDiscountList(prev => {
    //@ts-ignore
    if (prev.includes(`discount.discount${productPrice?.productPriceId}`)) {
      let products: PracticeProductInfo[] = getValues("practiceProducts");
      products = setDiscount(allowableUnitPrice, discount, products, productPrice?.productPriceId)
      setValue("practiceProducts", products);
    }
      return prev;
    });
  };

  const convertToFloat = (str: string) => {
    const cleanedStr = str && str.replace(/,/g, "");
    return parseFloat(cleanedStr);
  };

  const setCustomPriceChange = (
    prod: ProductInfo | undefined,
    allowableUnitPrice: any,
    value: string,
    productPrice?: ProductPrice
  ) => {
    console.log(getValues())
    console.log(productPrice?.productPriceId)
    setEnabledCustomPrice(prev => {
      console.log(prev)
    //@ts-ignore
    if (prev.includes(`customPrice.customPrice${productPrice?.productPriceId}`)) {
      let products: PracticeProductInfo[] = getValues("practiceProducts");
      products = setCustomPrice(allowableUnitPrice, convertToFloat(value), products, productPrice?.productPriceId)
      setValue("practiceProducts", products);
    }
      return prev;
    });
  };

  const deleteDiscountComponentRow = (productPriceId?: string) => {
    let discounts = getValues("discount");
    let customPriceOpt = getValues('customPrice');
    let radio = getValues('radio');
    // @ts-ignore
    delete discounts[`discount${productPriceId}`];
    // @ts-ignore
    delete radio[`discount${productPriceId}`];
    // @ts-ignore
    delete customPriceOpt[`customPrice${productPriceId}`];
    setValue("discount", discounts);
    setValue("radio", radio);
    setValue("customPrice", customPriceOpt);
  };

  const handleDiscount = (    
    prod: ProductInfo | undefined,
    allowableUnitPrice: any,
    customPrice: number | null,
    type: string,
    productPrice?: ProductPrice) => {
      let products: PracticeProductInfo[] | null = getValues("practiceProducts");
      //@ts-ignore
      const discount: number | string = getValues(`discount.discount${productPrice?.productPriceId}`) ? getValues(`discount.discount${productPrice?.productPriceId}`) : "";
      if(type === 'customDiscount') {
        products = setDiscount(allowableUnitPrice, discount.toString() , products);
        //@ts-ignore
        setEnabledDiscountList(prev => { 
          const newVal = [...prev, `discount.discount${productPrice?.productPriceId}`];
          return newVal;
        });
        setEnabledCustomPrice(prev => {
          const newVal = prev.filter(discount => discount !== `customPrice.customPrice${productPrice?.productPriceId}`);
          return newVal;
        });
        //@ts-ignore
        setValue(`customPrice.customPrice${productPrice?.productPriceId}`, "");
        setProductRows((prevRows) => {
          return prevRows.map((row) => {
            if (row.productPriceId === productPrice?.productPriceId) {
              return {
                ...row,
                discount: discountComponent(prod, allowableUnitPrice, customPrice, "customPrice", productPrice)
              };
            }
            return row;
          });
        });
      } else {
        //@ts-ignore
        setValue(`customPrice.customPrice${productPrice?.productPriceId}`, customPrice)
        setEnabledDiscountList(prev => {
          const newVal = prev.filter(customPrice => customPrice !== `discount.discount${productPrice?.productPriceId}`);
          return newVal;
        });
        //@ts-ignore
        setEnabledCustomPrice(prev => {
          const newVal = [...prev, `customPrice.customPrice${productPrice?.productPriceId}`]
          return newVal;
        });
        products?.map((product) => {
          if (product.productPriceId === productPrice?.productPriceId) {
            //@ts-ignore
            product.extendedPrice = getValues(`customPrice.customPrice${productPrice?.productPriceId}`);
            product.discountType = "CUSTOM";
          }
        });
        setProductRows((prevRows) => {
          return prevRows.map((row) => {
            if (row.productPriceId === productPrice?.productPriceId) {
              return {
                ...row,
                //@ts-ignore
                extendedPrice: priceFormatter(getValues(`customPrice.customPrice${productPrice?.productPriceId}`)),
                discount: discountComponent(prod, allowableUnitPrice, customPrice, "customDiscount", productPrice)
              };
            }
            return row;
          });
        });
        //@ts-ignore
        setValue(`discount.discount${productPrice?.productPriceId}`, "")
      };
      setValue("practiceProducts", products);
  } 

  const handleChangePracticeMacDropDown = (event: any, value: any) => {
    setValue("macId", value);

    setValue("practiceProducts", []);
    setProductRows([])
  }

  const handleChangePracticeName = (event: any, value: any) => {
    let products: PracticeProductInfo[] = getValues("practiceProducts");
    setValue("practiceId", value);

    if (getValues("practiceId")) {
      fetchPracticeInfoAndPracticeProducts();
    }

    if (products.length > 0) {
      setIsPracticeDisable(true);
    }
  };

  let errorObj: SingleAlertInfo = {
    message: ``,
    alertType: "error",
  };

  const getEffectivePracticeProduct = (id: string) => {
    const selectedProduct = productsByUserRole.find(
      (prod) => prod.productId === id
    );
    const productPrices = selectedProduct?.productPrices;
    const effectiveDate = dayjs(getValues("effectiveDate"));
    let validProductPrice: ProductPrice | null = null;
    if (productPrices && effectiveDate.isValid()) {
      for (let i = 0; i < productPrices.length; i++) {
        const productPriceEffective = dayjs(productPrices[i].effectiveDate);
        // CS-633 Stage changes for stage error
        const productPriceInactive = dayjs(productPrices[i].inactiveDate);
        //
        if (
        // CS-633 Stage changes for stage error
          (!productPriceInactive.isValid() ||
           (effectiveDate.isBefore(productPriceInactive)) || (effectiveDate.isSame(productPriceInactive)) ) &&
           //
          ( effectiveDate.isAfter(productPriceEffective) || effectiveDate.isSame(productPriceEffective) )
        ) {
          validProductPrice = productPrices[i];
        }
      }
    }
    return validProductPrice;
  };


  const verifyAddProduct = () => {
    if (practiceData?.billingType === "INDIVIDUAL") {
      const selectedProductId = getValues("product");
      const selectedMacId = getValues("macId");
      const associatedProviders: number[] = practiceProductData
        .filter(product => product.productId === selectedProductId && product.macId === selectedMacId)
        .map(product => product.practiceProviderId)
        .filter((practiceProviderId): practiceProviderId is number => practiceProviderId !== null);

      const allProviders = practiceData?.practiceProviders || [];

      const providersAssociatedWithProduct = allProviders.filter(provider =>
        associatedProviders.includes(provider.providerId)
      );

      const providersToBeAssociated = allProviders.filter(
        provider => !associatedProviders.includes(provider.providerId)
      );

      const getProviderName = (provider: any) => {
        const title = provider.title ? provider.title + " " : "";
        const fullName = `${title}${provider.firstName} ${provider.lastName}`.trim();
        return fullName;
      };

      const providerNamesAssociatedWithProduct = providersAssociatedWithProduct.map(getProviderName);
      const providerNamesToBeAssociated = providersToBeAssociated.map(getProviderName);

      if (providerNamesToBeAssociated.length == 0) {
        setShowOptionsPopup({
          message: "Agreement(s) for this product already exist for every provider.",
          show: true,
          variant: "warning",
          buttons: [
            {
              name: "Cancel",
              color: "secondary",
              onClick: () => {
                cancelOptionPopup()
              },
            },
          ],
        });
      } else if (providerNamesAssociatedWithProduct.length == 0) {
        addProduct()
      } else if (providersAssociatedWithProduct.length != 0 && providerNamesToBeAssociated.length != 0) {
        let providersMessage = ``;
        providersMessage += `Agreement(s) for this product are already available for:\n${providerNamesAssociatedWithProduct.join("\n ")}\n\n`;

        providersMessage += `New agreement(s) will be created for:
          ${providerNamesToBeAssociated.join("\n ")}\n\n`;

        providersMessage += `Do you want to add this product?`;

        setShowOptionsPopup({
          message: providersMessage,
          show: true,
          variant: "info",
          buttons: [
            {
              name: "Confirm",
              color: "primary",
              onClick: () => {
                addProduct();
                setShowOptionsPopup({ message: "", show: false });
              },
            },
            {
              name: "Cancel",
              color: "secondary",
              onClick: () => {
                cancelOptionPopup()
              },
            },
          ],
        });
      }
    } else {
      addProduct();
    }
  }
  
    const handleProductSelect = () => {
    const effectiveDate = getValues('effectiveDate');
    dispatch(updateElementInProductListSlice({
      elementName: "ProductPriceListForSingleProduct",
      value: ""
    }));
    const productId = getValues('product');
    const productPriceByProdIdObj : ProductPriceByProductRequest = {
      productId: productId ? parseInt(productId) : 0,
      effectiveDate : effectiveDate && dayjs(effectiveDate).isValid() ? effectiveDate : dayjs().format("MM/dd/yyyy")
    }
    productId && dispatch(getProductPriceByProductId(productPriceByProdIdObj));
  }

  const checkProductPriceValidity = (pp: ProductPrice) => {
    let selectedMacId = getValues("macId");
    if(selectedMacId && pp.macId === selectedMacId) {
      return true;
    } else if(selectedMacId && pp.macId !== selectedMacId) {
      return false;
    } else if( !selectedMacId && parseInt(pp.macId) > 0) {
      return false;
    } else {
      return true;
    }
  };

  const addProduct = async () => {
    let products: PracticeProductInfo[] = getValues("practiceProducts") || [];
    const selectedProductId = getValues("product");
    const addingProduct = productRows.find(
      (prod) => prod.productId === selectedProductId
    );
    const effectiveDate = dayjs(getValues("effectiveDate"));
    if (!selectedProductId) {
      errorObj.message = "Please select a Product";
      dispatch(setSingleAlertObj(errorObj));
    } else if (addingProduct) {
      errorObj.message = "The product has already been added.";
      dispatch(setSingleAlertObj(errorObj));
    } else if (!effectiveDate || !effectiveDate.isValid()) {
      errorObj.message = "Please select a valid date.";
      dispatch(setSingleAlertObj(errorObj));
    } else {
      if (!addingProduct) {
        let selectedProduct = productsByUserRole?.find(
          (prod) => prod.productId === selectedProductId
        );
        if (selectedProduct) {
          
          let rows: any[] = [];
          ProductPriceListForSingleProduct?.forEach((pp: ProductPrice) => {
          const valid = checkProductPriceValidity(pp);

           if(selectedProduct && valid) {
            products.push({
              productId: selectedProduct.productId,
              extendedPrice: null,
              productPriceId: pp.productPriceId,
              productItemId: pp.productItem?.productItemId,
              practiceProviderId: null,
              macId: pp.macId
            });
            let row = {
              productId: selectedProduct.productId,
              name: selectedProduct?.productName,
              discount: discountComponent(
                selectedProduct,
                pp.allowableUnitPrice,
                pp.customPrice,
                undefined,
                pp
              ),
              price: pp.allowableUnitPrice ? parseInt(pp.macId ) > 0 ? priceFormatter(pp.allowableUnitPrice) + " (Local)" : priceFormatter(pp.allowableUnitPrice) + " (National)" : "No Local or National Fee",
              extendedPrice: null,
              productPriceId: pp.productPriceId,
              productItemId: pp.productItem?.productItemId,
              sku: pp.productItem?.sku,
              uniqueId: pp.productPriceId,
              allowableUnitPrice : pp.allowableUnitPrice
            }
            rows.push(row);
           }
          });
          if (rows.length === 0) {
            errorObj.message = "No Local or National fee for the selected MAC.";
            dispatch(setSingleAlertObj(errorObj));
          }
          setProductRows(prevRows => {
            let newRows = [...prevRows, ...rows]
            return newRows;
          });
          setValue("practiceProducts", products);
          
        }
      }
    }
    const practiceId = getValues("practiceId");
    let selectedMacId = getValues("macId");
    if (products.length > 0) {
      if (practiceId) {
        setIsPracticeDisable(true);
      }
      if (dayjs(getValues("effectiveDate")).isValid()) {
        setIsEfctDateDisable(true);
      }
      if(selectedMacId) {
        setIsMacDisable(true);
      }
    }
  };

  const onSubmit = (data: any) => {
    const {
      discount,
      product,
      practiceMacDropDown,
      close,
      practiceName,
      extendedValues,
      customPrice,
      radio,
      ...agreementObj
    } = data;
  
      if( !dayjs(data.effectiveDate).isValid()) {
        let errorObj: SingleAlertInfo = {
          message: "Please select valid effective date.",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errorObj));
      } else {
        if (agreementObj.practiceProducts?.length === 0) {
          let errorObj: SingleAlertInfo = {
            message: "Please select atleast one product.",
            alertType: "error",
          };
          dispatch(setSingleAlertObj(errorObj));
        } else if (
          agreementObj.practiceProducts.find(
            (prod: any) => prod.discountType === undefined 
            || prod.discountType === '' 
            || (prod.extendedPrice === null || isNaN(prod.extendedPrice))
          )
        ) {
          let errorObj: SingleAlertInfo = {
            message: "Please select a discount or enter a custom price for each added product.",
            alertType: "error",
          };
          dispatch(setSingleAlertObj(errorObj));
        } else {
          setShowOptionsPopup({
            message: "Do you really want to create the onboarding agreement? ",
            show: true,
            variant: "confirm",
            buttons: [
              {
                name: "Confirm",
                color: "primary",
                onClick: () => createAgreement(agreementObj),
              },
              { name: "Cancel", color: "secondary", onClick: cancelOptionPopup },
            ],
          });
        };
      };
  };

  const extractIndex = (str: string) => {
    const match = str.match(/\[(\d+)\]/);
    return match ? Number(match[1]) : null;
  };

  const createAgreement = async (agreementObj: any) => {
    const response = await dispatch(createProductAgreement( { agreementData : agreementObj, interceptorOption: { skipErrorPopup: true } } ));
    if(response?.payload?.code === RESPONSE_CODES.SUCCESS_CODE) {

      const dataArray = response?.payload?.data || [];

      const createdProviders = dataArray
          .flatMap((item: any) => item.agreementCreatedProviders || []);
      const notCreatedProviders = dataArray
          .flatMap((item: any) => item.agreementNotCreatedProviders || []);

      const seenCreatedProviderIds = new Set();
      const distinctCreatedProviders = createdProviders.filter((provider: any) => {
          if (!seenCreatedProviderIds.has(provider.providerId)) {
              seenCreatedProviderIds.add(provider.providerId);
              return true;
          }
          return false;
      });

      const seenNotCreatedProviderIds = new Set();
      const distinctNotCreatedProviders = notCreatedProviders.filter((provider: any) => {
          if (!seenNotCreatedProviderIds.has(provider.providerId)) {
              seenNotCreatedProviderIds.add(provider.providerId);
              return true;
          }
          return false;
      });

      const createdNames = distinctCreatedProviders
          .map((provider: any) => `${provider.title} ${provider.firstName} ${provider.lastName}`)
          .join(", ");
      const notCreatedNames = distinctNotCreatedProviders
          .map((provider: any) => `${provider.title} ${provider.firstName} ${provider.lastName}`)
          .join(", ");

      let alertMessage = "";

      if (distinctCreatedProviders.length > 0) {
        alertMessage += `\nFor: ${createdNames}.`;
      }

      let successObj: SingleAlertInfo = {
        title: "Product Agreement(s) have been created.",
        message: alertMessage,
        alertType: "success",
      };
      dispatch(setSingleAlertObj(successObj));
      navigate(-1);
    } else if( response?.payload?.code === RESPONSE_CODES.FAILED_CODE) {
      if(response?.payload?.message === MSG_ACTIVE_PRODUCT_AGREEMENT_FOUND && response?.payload?.errors) {
        let productNames: string[] = [];
        response?.payload?.errors.map((error: any) => {
          const index = extractIndex(error.field);
          if(index !== null) {
            productNames.push(productRows[index].name)
            let errObj: SingleAlertInfo = {
              message: `Agreement(s) already available for ( ${productNames} )`,
              alertType: "error",
            };
            dispatch(setSingleAlertObj(errObj));
          }
        })
      } else {
        let errObj: SingleAlertInfo = {
          message: response?.payload?.message || "Something went wrong.",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errObj));
      }
    };
    cancelOptionPopup();
  };

  const cancelOptionPopup = () => {
    setShowOptionsPopup({ message: "", show: false });
  };

  const handleDateChangeEffective = (date: Date | null) => {
    setValue("effectiveDate", dayjs(date).format("MM/DD/YYYY"));
    let products: PracticeProductInfo[] = getValues("practiceProducts");
    if (products.length > 0) {
      setIsEfctDateDisable(true);
    }
    setValue("product", "");
  };

  // const handleDateChangeInactive = (date: Date | null) => {
  //   setValue("inactiveDate", dayjs(date).format("MM/DD/YYYY"));
  // };

  const handlePracticeDetailsShow = () => {
    const selectedPracticeId = getValues("practiceId");

    if (selectedPracticeId) {
      setShowPracticeDetails(true);
    } else {
      errorObj.message = "Please select a Practice,";
      dispatch(setSingleAlertObj(errorObj));
    }
  };

  const MACOptions: Option[] = useMemo(() => {
    const options: Option[] = [{ label: "Select", value: "" }];
    macInfoList?.map((mac) => {
      options.push({
        value: mac.macId,
        label: mac.macName
      })
    });
    return options
  }, [macInfoList]);

  const productOptions: Option[] = useMemo(() => {
    let options: Option[] = [];
    const sortedProductList: ProductInfo[] = sortNames(productsByUserRole, "productName")
    sortedProductList?.map((prod) => {
      options.push({ value: prod.productId, label: prod.productName });
    });
    return options;
  }, [productsByUserRole]);

  const practiceOptions: Option[] = useMemo(() => {
    let options: Option[] = [];
    if(practiceByUserRole) {
      const approvedPracticeList = practiceByUserRole.filter(
        (practice) => practice.approvalStatus === "APPROVED"
      );
      const sortedPracticeInfo: PracticeInfo[] = sortNames(
        approvedPracticeList,
        "practiceName"
      );
      sortedPracticeInfo?.map((practice) => {
        options.push({
          value: practice.practiceId,
          label: practice.practiceName,
        });
      });
    }
    return options;
  }, [practiceByUserRole]);

  const effectiveDate = useWatch({
    control,
    name: 'effectiveDate'
  });

  useEffect(() => {
    if(dayjs(effectiveDate).isValid()) {
      setMaxInactiveDate(dayjs(effectiveDate).add(20, 'year'));
      setMinInactiveDate(dayjs(effectiveDate));
    }
  }, [effectiveDate])


  const fetchPracticeInfoAndPracticeProducts = async () => {
    const selectedPracticeId = getValues("practiceId");
    try {
      const responsePractice = await dispatch(fetchPracticeByIdData(selectedPracticeId ? selectedPracticeId : ""))
      setPracticeData(responsePractice.payload.data)

      const responseProduct = await dispatch(fetchPracticeProductsData(selectedPracticeId ? selectedPracticeId : ""));
      setPracticeProductData(responseProduct.payload.data)

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDeleteMainRow = (productId: number) => {
    const relatedRows = productRows.filter((row) => row.productId === productId);
    relatedRows.forEach((row) => {
      if (row.productPriceId) {
        handleClose(row.productPriceId); // Pass productPriceId to handleClose
      }
    });
    resetDiscountComponent(productId);
  };

  const resetDiscountComponent = (productId: number) => {
    //@ts-ignore
    setValue(`customDiscountMain${productId}`, "");
    //@ts-ignore
    setValue(`customPriceMain${productId}`, "");
    //@ts-ignore
    setValue(`radioMain${productId}`, "");
  };
  

  const closeComponentMainRow = (productId: number): JSX.Element => {
    return (
      <FormControl>
        <CustomIconButton
          color="warning"
          size="medium"
          variant="outlined"
          onClick={() => handleDeleteMainRow(productId)}
        >
          <DeleteOutlineOutlined fontSize="small" />
        </CustomIconButton>
      </FormControl>
    );
  };

  const mainDiscountComponent = (
    productId: number,
    control: any,
    setValue: any,
    getValues: any
  ): JSX.Element => {
  
    const radioOptions = [
      {
        value: "customDiscountMain",
        label: (
          <CustomTextField
            control={control}
            controllerName={`customDiscountMain${productId}`}
            label="Discount %"
            onChange={(e) => handleMainDiscountChange(e, "customDiscountMain", productId)}
            type="number"
            rules={{
              min: { value: 1, message: "Min 1%" },
              max: { value: 100, message: "Max 100%" },
            }}
            size="small"
            style={{ width: "150px" }}
            disabled = {isCustomPriceRadio? true: false}
          />
        ),
      },
      {
        value: "customPriceMain",
        label: (
          <CustomCurrencyField
            control={control}
            controllerName={`customPriceMain${productId}`}
            label="Custom Price"
            onChange={(e) => handleMainDiscountChange(e, "customPriceMain", productId)}
            size="small"
            style={{ width: "150px" }}
            disabled = {isCustomDiscountRadio ? true: false}
          />
        ),
      },
    ];
  
    return (
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <CustomRadioButton
            control={control}
            controllerName={`radioMain${productId}`}
            options={radioOptions}
            onChange={(e) => handleRadioChange(e.target.value, productId)}
          />
        </Grid>
      </Grid>
    );
  };

  const handleRadioChange = (selectedValue: string, productId: number) => {
    productRows.forEach((row) => {
      if (row.productId === productId) {
        //@ts-ignore
        setValue(`radio.discount${row.productPriceId}`, selectedValue === "customDiscountMain" ? "customDiscount" : "customPrice");
  
        if (selectedValue === "customDiscountMain") {
          //@ts-ignore
          setValue(`customPrice.customPrice${row.productPriceId}`, "");
        } else {
          //@ts-ignore
          setValue(`customPrice.customPrice${row.productPriceId}`, row.allowableUnitPrice ? priceFormatter(row.allowableUnitPrice) : "");
          //@ts-ignore
          setValue(`discount.discount${row.productPriceId}`, "");
        }
        let products: PracticeProductInfo[] = getValues("practiceProducts");
        setProductRows((prevRows) =>
          prevRows.map((r) =>
            r.productPriceId === row.productPriceId
              ? { ...r, extendedPrice:  selectedValue === "customPriceMain" ? priceFormatter(row.allowableUnitPrice) : null }
              : r
          )
        );
        products?.map((product) => {
          if (product.productPriceId === row?.productPriceId) {
            product.discountType = selectedValue === "customPriceMain" ? "CUSTOM" : "";
            product.extendedPrice = selectedValue === "customPriceMain" ? row?.allowableUnitPrice : null;
          }
        }); 
        setValue("practiceProducts", products);
      }

    });
    if (selectedValue === "customDiscountMain") {
      //@ts-ignore
      setValue(`customPriceMain${productId}`, "");
      setCustomPriceRadio(false);
      setCustomDiscountRadio(true);
    } else if(selectedValue === "customPriceMain") {
      //@ts-ignore
      setValue(`customDiscountMain${productId}`, "");
      setCustomPriceRadio(true);
      setCustomDiscountRadio(false);
    }

    
  };
  

  const handleMainDiscountChange = (event: any, type: string, productId: number) => {
    productRows.forEach((row) => {
      let products: PracticeProductInfo[] = getValues("practiceProducts");
      if (row.productId === productId) {
        if(type === "customPriceMain" ){
            //@ts-ignore
            setValue(`customPrice.customPrice${row?.productPriceId}`,event?.target?.value || "");
            products = setCustomPrice(row.allowableUnitPrice, convertToFloat(event?.target?.value), products, row?.productPriceId)
        }else{
            //@ts-ignore
            setValue(`discount.discount${row?.productPriceId}`,event?.target?.value || "");
            products = setDiscount(row.allowableUnitPrice, event?.target?.value, products, row?.productPriceId)
        }
        setValue("practiceProducts", products);
      }
    });
  };

  const updateExtendedPrice = (row: any, type: string, value: string) => {
    
    let newExtendedPrice :any = null;
  
    if (type === "customDiscountMain") {
      const percentageValue = value  ;
      const allowableUnitPriceValue = row.allowableUnitPrice;

      newExtendedPrice = calculateValue(percentageValue, allowableUnitPriceValue);

      
    } else if (type === "customPriceMain") {
      newExtendedPrice = convertToFloat(value);
    }
    // Update the extended price in productRows
    setProductRows((prevRows) =>
      prevRows.map((r) =>
        r.productPriceId === row.productPriceId
          ? { ...r, extendedPrice:  priceFormatter(newExtendedPrice) }
          : r
      )
    );
  };

  return (
    <>
      <PracticeDetailsPopup
        open={showPracticeDetails}
        onClose={() => setShowPracticeDetails(false)}
        practiceId={getValues("practiceId")}
      />
      {showOptionPopup.show && (
        <OptionsPopup
          open={showOptionPopup.show}
          onClose={() => setShowOptionsPopup({ message: "", show: false })}
          variant={showOptionPopup.variant}
          message={<>{showOptionPopup.message}</>}
          buttons={showOptionPopup.buttons}
          title={showOptionPopup.title}
          obj={showOptionPopup.obj}
        />
      )}
      <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
        <Header
          title="Manufacturer Onboarding Agreement"
          subtitle="Create Onboarding Agreement"
        />
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <CustomDropdownField
                    label="Practice"
                    name="practiceName"
                    options={practiceOptions}
                    control={control}
                    onChangeSelect={handleChangePracticeName}
                    rules={{ required: "practice is Required." }}
                    disabled={isPracticeDisable}
                  />
                </Grid>
                <Grid item xs={12} md="auto">
                  <Button
                    type="button"
                    color="info"
                    style={{ top: "50%", transform: "translateY(-50%)" }}
                    onClick={() => handlePracticeDetailsShow()}
                    startIcon={<Details />}
                    fullWidth
                  >
                    Practice Details
                  </Button>
                </Grid>
                <Grid item xs={12} md={4} />

                <Grid item xs={12} md={4}>
                  <CustomDatePicker
                    label="Effective Date"
                    openTo="day"
                    controllerName="effectiveDate"
                    control={control}
                    onChange={handleDateChangeEffective}
                    disabled={isEfctDateDisable}
                    rules={{
                      required: "Effective Date is required.",
                      validate: {
                        isValidDate: (
                          value:
                            | string
                            | number
                            | Date
                            | dayjs.Dayjs
                            | null
                            | undefined
                        ) =>
                          dayjs(value, "MM/DD/YYYY", true).isValid() ||
                          "Invalid date format.",
                      },
                    }}
                  />
                </Grid>
                {/* CS-559  */}
                {/* <Grid item xs={12} md={8} />
                <Grid item xs={12} md={4}>
                <CustomDatePicker
                    label="Inactive Date"
                    openTo="day"
                    controllerName="inactiveDate"
                    control={control}
                    onChange={handleDateChangeInactive}
                    //@ts-ignore
                    maxDate={maxInactiveDate}
                    //@ts-ignore
                    minDate={minInactiveDate}
                  />
                </Grid> */}
                <Grid item xs={12} md={8} />
                <Grid item xs={12} md={4}>
                  <CustomDropdownField
                    control={control}
                    name="practiceMacDropDown"
                    options={MACOptions}
                    label="MAC"
                    disabled={isMacDisable}
                    onChangeSelect={handleChangePracticeMacDropDown}
                  />
                </Grid>
                <Grid item xs={12} md={8} />

                <Grid item xs={12} md={4}>
                  <CustomDropdownField
                    control={control}
                    name="product"
                    options={productOptions}
                    label="Product"
                    onChangeSelect={handleProductSelect}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    type="button"
                    variant="outlined"
                    color="info"
                    style={{ top: "50%", transform: "translateY(-50%)" }}
                    onClick={() => verifyAddProduct()}
                    startIcon={<AddCircleOutlineOutlined />}
                    fullWidth
                  >
                    Add Product
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} />

                {/* <Grid item xs={12}>
                  <CustomTable
                    columns={productColumns}
                    rows={productRows}
                    disablePagination={true}
                    emptyText="No Product(s) Found"
                    actionComponent={(row: any) => closeComponent(row.productPriceId )}
                  />
                </Grid> */}
                <Grid item xs={12}>
                <Box margin={2} border={1} borderColor="#ddd" borderRadius="10px" padding="10px">
                <TableContainer style={{ marginTop: '20px', borderRadius: '10px' }}>
                    <Table>
                      {/* Main Table Header */}
                      <TableHead>
                        <TableRow>
                          <TableCell />
                            {productColumns.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {productRows
                          .reduce<GroupedRow[]>((acc, row) => {
                            const isProductWithSKU = !!row.sku && !!row.price;

                            // Group rows by product for sub-tables
                            const existingMainRow = acc.find(
                              (item) =>
                                item.mainRow.name === row.name &&
                                isProductWithSKU &&
                                item.mainRow.productId === row.productId
                            );

                            if (!existingMainRow && isProductWithSKU) {
                              acc.push({ mainRow: { ...row, extra: true }, subRows: [row] });
                            } else if (existingMainRow && isProductWithSKU) {
                              existingMainRow.subRows.push(row);
                            } else {
                              acc.push({ mainRow: row, subRows: [] });
                            }
                            return acc;
                          }, [])
                          .map(({ mainRow, subRows }: GroupedRow, mainIndex: number) => (
                            <React.Fragment key={mainIndex}>
                              {/* Main Row */}
                              <TableRow
                              sx={{
                                backgroundColor: colors.grey[900], // Light gray for extra rows, white for others
                                "&:hover": {
                                  backgroundColor: colors.primary[500], // Hover effect
                                },
                              }}>
                                <TableCell>
                                  {subRows.length > 0 && (
                                    <IconButton
                                      size="small"
                                      color="success"
                                      onClick={() => handleCollapseToggle(mainIndex)}
                                    >
                                      {collapsedState[mainIndex] ? (
                                        <KeyboardArrowDown />
                                       
                                      ) : (
                                        <KeyboardArrowUp />
                                      )}
                                    </IconButton>
                                  )}
                                </TableCell>
                                <TableCell>{mainRow.name}</TableCell>
                                {/* <TableCell>{mainRow.extra ? "-" : mainRow.sku || ""}</TableCell> */}
                                <TableCell>{mainRow.extra ? "-" : mainRow.price || "No Price"}</TableCell>
                                <TableCell>{mainRow.extra ?  mainDiscountComponent(mainRow.productId, control, setValue, getValues) : mainRow.discount || "N/A"}</TableCell>
                                <TableCell>{mainRow.extra ? "-" : mainRow.extendedPrice || "N/A"}</TableCell>
                                <TableCell>{mainRow.extra ? closeComponentMainRow(mainRow.productId) : closeComponent(mainRow.productPriceId)}</TableCell>
                              </TableRow>


                              {/* Sub-Table */}
                              {!collapsedState[mainIndex] && mainRow.extra && (
                                <TableRow>
                                  <TableCell colSpan={productColumns.length + 1} style={{ padding: 0 }}>
                                    <Box margin={2} border={1} borderColor="#ddd" borderRadius="10px">
                                      <TableContainer style={{ marginTop: '20px', borderRadius: '10px' }}>
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                          {subColumns.map((column) => (
                                            <TableCell
                                              key={column.id}
                                              align={column.align}
                                              style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                                            >
                                              {column.label}
                                            </TableCell>
                                          ))}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {subRows.map((subRow, subRowIndex) => (
                                            <TableRow key={`${mainIndex}-${subRowIndex}`}>
                                              <TableCell>{subRow.sku}</TableCell>
                                              <TableCell>{subRow.price}</TableCell>
                                              <TableCell >{subRow.discount}</TableCell>
                                              <TableCell >{subRow.extendedPrice}</TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>

                                      </TableContainer>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              )}
                            </React.Fragment>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                justifyContent="flex-end"
                sx={{ mt: 1, flexWrap: "wrap" }}
                spacing={1}
              >
                <Grid item xs={12} sm="auto">
                  <Button type="submit" startIcon={<Save />} fullWidth>
                    Create Agreement
                  </Button>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Button
                    color="error"
                    onClick={() => navigate(-1)}
                    startIcon={<Cancel />}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Card>
      </Box>
    </>
  );
};

export default CreateNewAgreementNew;
